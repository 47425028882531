export const CHAIN_ID = 1;

export const API_URLS: { [chainId: number]: string } = {
  1: 'https://tbziw4f7f4.execute-api.us-west-2.amazonaws.com/dev/',
  4: 'https://tbziw4f7f4.execute-api.us-west-2.amazonaws.com/dev/',
};

export const RPC_URLS: { [chainId: number]: string } = {
  1: 'https://mainnet.infura.io/v3/3b3afd5f4a7f49b5a7711461ed9df1f2',
  4: 'https://rinkeby.infura.io/v3/3b3afd5f4a7f49b5a7711461ed9df1f2',
};

export const PSS_CONTRACT_ADDRESS: { [chainId: number]: string } = {
  1: '0x76e51bc8fa0beebcf3e45de408fa43c9dc5ffb27',
  4: '0x0565e05b49a8919c1D79c0626885bc1a2821f48E',
};

export const SILVERBACK_NFT_CONTRACT_ADDRESS: { [chainId: number]: string } = {
  1: '0x912f8a729166720B7FB7fB00AbA7c89C432B160C',
  4: '0xCf940981892035b2076aaa2C103206f816B4467f',
};

export const SILVER_TOKEN_CONTRACT_ADDRESS: { [chainId: number]: string } = {
  1: '0x2B7BE84059f3aB5A550720213623a3abA8b36dbE',
  4: '0x1E8e4A15De4EF08Fa8575E6E5c9E03F1191e5F84',
};

export const CONTRACT_ADDRESS: { [chainId: number]: string } = {
  1: '0x0641c5Bd33B0e71e441cC65b7d9bC0182276b185',
  4: '0x7ffD136348f4BB83fa3Fd9eB69DF7B5E2DcBE39C',
};
export const SCAN_BASE_URI: { [chainId: number]: string } = {
  1: 'https://etherscan.io',
  4: 'https://rinkeby.etherscan.io',
};

export const SILVERBACK_OPENSEA_URI: { [chainId: number]: string } = {
  1: 'https://opensea.io/account/pss-silverbacks-official?search[sortBy]=LAST_TRANSFER_DATE',
  4: 'https://testnets.opensea.io/account/pss-silverback-official?search[sortBy]=LAST_TRANSFER_DATE',
};

export const OPENSEA_URI: { [chainId: number]: string } = {
  1: 'https://opensea.io/account/pss-bingo?search[sortBy]=LAST_TRANSFER_DATE',
  4: 'https://testnets.opensea.io/account/pss-bingo?search[sortBy]=LAST_TRANSFER_DATE',
};

export const METAMASK_LAUNCHER: { [chainId: number]: string } = {
  1: 'https://metamask.app.link/dapp/www.primatesocialsociety.com/',
  4: 'https://metamask.app.link/dapp/naughty-boyd-ea3834.netlify.app/',
};

export const APESQUAD: {
  [chainId: number]: {
    characterSheetNFT: string;
    characterSheetOpenSeaUri: string;
    posterNFT: string;
    posterOpenSeaUri: string;
    minter: string;
  };
} = {
  1: {
    characterSheetNFT: '0x08FED8E459BeB705320533B684b750100482Ced0',
    characterSheetOpenSeaUri: '',
    posterNFT: '0x2937842Aa8179690e67B417adE5D117a58D70400',
    posterOpenSeaUri: '',
    minter: '0x66e22D7b3040Ea48E4b01eA0FBC1713CeAE75Aa8',
  },
  4: {
    characterSheetNFT: '0xE6f15b4f966830D172D21DB53874a08EbC74214A',
    characterSheetOpenSeaUri: '',
    posterNFT: '0xae049a5556675544e26A88c100008F6378A08D04',
    posterOpenSeaUri: '',
    minter: '0xC2b9d3973D4aA7f7cAAa8C51197507d6Bfffe12A',
  },
};
