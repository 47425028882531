import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import DAppProvider from './library/providers/DAppProvider';
import { Config } from './library/models/config/Config';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5DV268L',
};

TagManager.initialize(tagManagerArgs);

import { CHAIN_ID, RPC_URLS } from './constants';

const App = React.lazy(() => import('./App'));

const chainIds = Object.keys(RPC_URLS).map((key) => parseInt(key));

const AppConfig: Config = {
  defaultChainId: CHAIN_ID,
  readOnlyChainIds: chainIds,
  supportedChainIds: chainIds,
  autoSwitch: false,
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={AppConfig}>
      <React.Suspense fallback={<>...</>}>
        <App />
      </React.Suspense>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
